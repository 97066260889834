/* Provide sufficient contrast against white background */
#root {
  display: flex;
  flex-direction: column;
}
html,
body,
#root {
  height: 100%;
}
.flex-container {
  display: flex;
  background-color: #002e5e;
  font-family: "Open Sans", sans-serif;
  flex-direction: row;
  white-space: nowrap;
}
.test {
  height: 100%;
}
.card-title {
  height: 100%;
}
.header {
  display: flex;
  align-items: center;
}
.header a {
  text-decoration: none;
}
.img {
  height: 70px;
  width: 70px;
}
.text {
  font-size: 25px;
  color: white;
  border-left: 1px solid white;
  font-weight: 400;
  height: 55px;
  padding-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
}
.signedInInfo {
  margin: 0;
  color: white;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0 20px 0 0;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.btn-secondary {
  background-color: #fff;
  color: #002e5e;
}

.navbar-text {
  color: #144696;
  font-family: Open Sans, sans-serif;
  font-size: 18px;
  font-weight: 400;
  margin-right: 70px;
  white-space: nowrap;
}

.color a:hover {
  border-bottom: 3px solid #1a5ebd;
  text-decoration: none;
  margin-bottom: -3px;
}

.color a:active {
  border-bottom: 3px solid #002e5e;
  margin-bottom: -3px;
}

.color a:focus {
  border-bottom: 3px solid #002e5e;
  margin-bottom: -3px;
}

.color {
  display: flex;
  flex-direction: row;
  white-space: nowrap;
  justify-content: center;
  background-color: #f4f1f0;
  align-items: center;
}
