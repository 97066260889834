* {
  font-family: "Open Sans", sans-serif;
}

.container {
  height: 100%;
}

.form {
  display: flex;
  flex-direction: column;
  padding-top: 0% !important;

  /* padding: 0 50px 0 50px; */
}
.form:first-child {
  margin-top: -40px !important;
}
.form-text {
  font-weight: 600;
  padding-top: 15px;
  color: #545454;
  margin: 0;
}
.form-text-alert {
  color: red;
  font-weight: 600;
  padding-top: 15px;
  margin-bottom: 0;
}
.form-btn {
  padding: 18.5px 0;
  border: 0;
  color: blue;
  background: none;
  text-decoration: underline;
  text-underline-offset: 4px;
  align-self: flex-end;
}
.form-button {
  background-color: #1a5ebd;
  color: white;
  width: 200px;
  height: 50px;
  margin-top: 15px;
  margin-bottom: 20px;
  border-color: #1a5ebd;
}
.delete-button {
  display: flex;
  border-color: #cd4658;
  color: #cd4658;
  background-color: #ededed;
  width: 150px;

  margin-top: 30px;
  margin-bottom: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}
.delete-button:hover {
  background-color: #cd4658;
  color: white;
  border-color: #cd4658;
}

.slett-btn {
  display: flex;
  background-color: #cd4658;
  justify-content: center;
  align-items: center;
  border-color: #cd4658;
  color: white;
  width: 65px;
  height: 38px;
  margin-right: 30px;
  font-size: 20px;
}
.slett-btn:hover {
  background-color: #bf0019;
  border-color: #bf0019;
}

.warning {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  font-size: 20px;
  font-family: Open Sans, sans-serif;
}
.form-text-header {
  margin-top: -15px;
}
.warning-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 60px;
  font-family: Open Sans, sans-serif;
}

.avbryt-button {
  background-color: #fafafa;
  border-color: #333333;
  color: #545454;
  width: 80px;
  margin: 30px 20px 30px 0px;
  height: 38px;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  font-weight: 500;
}
.submit-button {
  background-color: #1a5ebd;
  color: white;
  width: 200px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-right: 20px;
  height: 32;
  font-weight: 00;
  font-size: 17px;
  font-weight: 500;
}
#lagre:hover {
  background-color: #13856c;
  border-color: #13856c;
}
#lagre:hover.disabled {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #848a8f;
  cursor: not-allowed;
}
#avbryt:hover {
  background-color: #333333;
  color: white;
}

.form-actions-delete {
  padding-top: 30px;
  display: flex;
  width: 100%;
  padding-left: 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.form-actions {
  display: flex;
  width: 1000%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}
.center-text {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}
.regjering-page {
  font-size: large;
  display: flex;
  padding-left: 20px;
  flex-direction: column;
}
.button-group {
  margin-right: 20px;
}
.departement {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
}

.button-icon {
  margin-left: 15px;
}
.name-wrapper {
  display: flex;
  flex-direction: row;
}

.politisk-radio {
  display: flex;
  flex-direction: row !important;
  padding-bottom: 20px;
}
.dialog-title {
  display: flex;
  justify-content: space-between;
  width: 100% !important;
  padding-bottom: 0%;
  margin-bottom: 0%;
}
.close-icon {
  justify-self: self-end;
}
.dialog-title-form {
  padding-bottom: 0% !important;
}

.overview-infoline {
  display: flex;
}
.overview-infoline-v {
  padding-top: 15px;
}
.detail-page {
  padding-bottom: 50px;
}
.overview-tag {
  font-weight: 600;
  color: #002e5e;
  padding-right: 10px;
  padding-bottom: 8px;
}
.overview-tag-alert {
  font-weight: 600;
  color: #cd4658;
  padding-right: 10px;
  padding-bottom: 8px;
}
.divider {
  padding-top: 25px;
  padding-bottom: 10px;
}
.role-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 60px 20px 60px;
  margin-top: 20px;
  background: #ededed;
}
.role-btn-group {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.date-text {
  color: grey;

  padding-top: 10px;
}

.edit {
  color: #1a5ebd;
  width: 250px;
  height: 30px;
}
.main-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.role-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.role-edit {
  margin: 30px;
  width: 150px;
  height: 30px;
}
.icon-style {
  color: #1a5ebd;
  font-size: 10px;
}
.tilbake-btn {
  color: #1a5ebd;
  cursor: default;
  text-decoration: underline;
  font: 400 16px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.role-name-title {
  margin-left: 35px;
  font-size: large;
  font-weight: 600;
}
.header-listitem {
  color: #3867c8;
  font-family: Open Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
}

.listitem-link {
  width: 100%;
}
.card-title {
  height: 100%;
}

.fullscreen-loading {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  margin: 40% 0% 0% 45%;
}
.loading-text {
  margin-left: 20px;
  padding-top: 25px;
}
.footer {
  background-color: #333333;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: auto;

  color: white;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  padding: 10px 0px 10px 0px;
}
.footer-text {
  display: flex;
  color: white;
  font-weight: 600;
  border-left: 1px solid white;
  align-items: center;
  font-size: 18px;
  padding-left: 15px;
  padding-right: 15px;
  height: 35px;
}
.text-box {
  display: flex;
  justify-content: flex-start;
  width: 50%;
  flex-direction: row;
  align-items: center;
  font-weight: 300;
  font-family: "Open Sans", sans-serif;
}
.logo {
  height: 60px;
  width: 60px;
}
.column {
  display: flex;
  flex-direction: column;
}
.footer-info {
  display: flex;
  align-content: center;
  flex-direction: column;
  font-size: 12px;
  padding-left: 20px;
  font-weight: 600;
  margin-left: 40px;
  padding: 15px;
}

.pagination {
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
  flex-wrap: wrap;
}

.search-info {
  margin: 15px 0px 10px 1px;
}
.rediger-button {
  width: 180px;
  align-items: center;
  height: 30px;
  padding: 2px;
  background-color: #1a5ebd;
  color: white;
  margin-right: 15px;
  border-color: #1a5ebd;
  white-space: nowrap;
}
.rediger-button:hover {
  background-color: #44638d;
}

.dialog-person {
  display: flex;
  flex-direction: column;

  align-items: stretch;
}

.form-date {
  font-weight: 600;
  margin-bottom: -15px;
  padding-top: 15px;
  color: #545454;
}
.form-date-alert {
  font-weight: 600;
  margin-bottom: -15px;
  color: red;
  padding-top: 15px;
}
.form-element-wrap {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.form-element-wrap > * {
  margin: 0 !important;
}
.gov-form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.small-text-field {
  width: 33%;
}

.med-text-field {
  width: 60%;
}
.input-field-select {
  width: 100%;
}
.input-field-date {
  width: 100%;
  margin: 0 !important;
}
.input-field-select > * {
  padding: 9px 14px;
}
.input-wrap_title {
  margin: 0;
}
.input-wrap_input {
  margin: 0;
}

.info-page {
  background-color: #ededed;
  padding: 40px 60px 15px 60px;
  margin-top: 30px;
  width: 100%;
}
.edit-detail {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.text-red {
  color: red;
  font-weight: bold;
}
.role-avvik {
  display: flex;
}
.list-header {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 30px;
}
.list-date {
  margin-top: 10px;
  color: black;
  font-size: 17px !important;
}

.list-show {
  margin-left: -12px;
}

.spaceFill {
  height: calc(100vh - 466px);
  display: flex;
  justify-content: center;
  align-items: center;
}
